import AccordionContainer from "@components/C11_VerticalAccordion/AccordionContainer";
import EmbeddableCheckbox from "@components/C138_EmbeddableForm/EmbeddableCheckbox";
import Grid from "@components/Grid/Grid";
import { CareerStagesDE } from "@customTypes/careerStagesDE";
import { CareerStagesEE } from "@customTypes/careerStagesEE";
import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { ProgrammeType } from "@customTypes/ProgrammeType";
import { useSettings } from "@utilities/context/settings";
import { fetchApi } from "@utilities/fetchApi";
import slugify from "@utilities/slugify";
import Link from "@components/Link/Link";
import { ReactElement, useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./C265_ProgrammesByCareerStage.module.scss";

export interface ProgrammesByCareerStageProps {
  heading1?: string;
  heading2?: string;
  introText?: string;
  programmeType: ProgrammeType;
  programmeAttribute: any;
  useBlueCssClass: boolean;
  tags: any;
}

const NUMBER_OF_COLUMNS = 3;

export const ProgrammesByCareerStage = (props: {
  content: ProgrammesByCareerStageProps;
}) => {
  const { content } = props;
  const {
    heading1,
    heading2,
    introText,
    programmeType,
    useBlueCssClass,
    tags,
  } = content;
  const { allTags } = useSettings();

  const useBlueCssClassExcpression = useBlueCssClass ? "blue" : "";
  const { programmeSettings } = useSettings();
  const [filterElement, setFilterElement] = useState<ReactElement>();
  const [selectedFilters, setSelectedFilters] = useState(new Set());
  const [programmeDetails, setProgrammeDetails] = useState<ProgrammeDetails[]>(
    []
  );
  const isEEtype = programmeType === ProgrammeType.EE;

  //get the collection of name id pair based on the tags on the page

  function getTagName(pageTags, allTags) {
    const tagsCollection = pageTags?.map((tag) => {
      const name = allTags?.find(
        (t) => t.id.toLocaleLowerCase() === tag.sys.id.toLocaleLowerCase()
      )?.name;
      return { name: name, id: tag?.sys?.id };
    });

    return tagsCollection;
  }

  // const c265topics = content.programmeAttribute;
  const c265topics = getTagName(tags, allTags);

  const programmeMatchesAnyFilters = ({
    programmeCareerStagesEeList,
    programmeCareerStagesDeList,
  }: ProgrammeDetails) => {
    const careerStageType = isEEtype
      ? programmeCareerStagesEeList
      : programmeCareerStagesDeList;

    if (careerStageType) {
      const programmeTags = careerStageType.map((tag) => slugify(tag));
      const filtersUsed = selectedFilters.size > 0;
      const matchesFilters = programmeTags.some((tag) =>
        selectedFilters.has(tag)
      );
      return filtersUsed && matchesFilters;
    } else {
      return false;
    }
  };

  const handleFilterChange = (tagKey, checked) => {
    if (checked) {
      setSelectedFilters((prev) => new Set(prev).add(tagKey));
    } else {
      setSelectedFilters((prev) => {
        const next = new Set(prev);
        next.delete(tagKey);
        return next;
      });
    }
  };

  const noItemsFound = programmeDetails.every(
    (d) => !programmeMatchesAnyFilters(d)
  );

  const getProgrammeColumns = (programmes: ProgrammeDetails[]) => {
    const columns: ProgrammeDetails[][] = new Array(NUMBER_OF_COLUMNS)
      .fill(null)
      .map(() => []);

    programmes
      .sort((a, b) => {
        const programmeFeeA = parseInt(
          (a.programmeImportData
            ? a.programmeImportData[0].programmeFee?.replace(/\D/g, "")
            : "") || "",
          10
        );
        const programmeFeeB = parseInt(
          (b.programmeImportData
            ? b.programmeImportData[0].programmeFee?.replace(/\D/g, "")
            : "") || "",
          10
        );

        if (!programmeFeeA || !programmeFeeB) return 0;

        if (programmeFeeA < programmeFeeB) {
          return 1;
        }
        if (programmeFeeA > programmeFeeB) {
          return -1;
        }

        return 0;
      })
      .forEach((programme, i) =>
        columns[i % NUMBER_OF_COLUMNS].push(programme)
      );

    return columns;
  };

  const getProgrammeMarkup = (programmes: ProgrammeDetails[]) =>
    programmes.map((programme) => (
      <AccordionContainer
        key={programme.programmeTitle}
        noHeading
        isProgramme
        isCompare={!isEEtype}
        programme={programme}
        customClass={clsx(
          styles["vertical-accordion"],
          !programmeMatchesAnyFilters(programme) &&
            selectedFilters.size > 0 &&
            styles["hide"]
        )}
        accordionFields={{
          accordionItems: [{ fields: { title: null, content: null } }],
          singleDisplay: false,
          defaultOpen: false,
          title: programme?.programmeTitle || "",
        }}
      />
    ));

  const getErrorMessage = () => (
    <div className={styles.error}>
      We haven&apos;t got any exact matches for your search at the moment.
      Please use our{" "}
      <Link href={programmeSettings.programmeFinderLink || ""}>
        programme finder
      </Link>
      to browse all of our executive education programmes
    </div>
  );

  useEffect(() => {
    const getData = async () => {
      const programmeDetails = await fetchApi(
        "/api/getProgrammesByCareerStageData",
        {
          programmeType,
        }
      );

      const filteredTopics: any = [];

      c265topics?.forEach((topic) => {
        programmeDetails.forEach((courseTopic) => {
          courseTopic?.programmeAttribute?.forEach((top) => {
            if (topic.name === top) {
              filteredTopics.push(courseTopic);
            }
          });
        });
      });

      setProgrammeDetails(filteredTopics);
    };
    getData();
  }, []);

  useEffect(() => {
    if (programmeDetails.length > 0) {
      let filterValues: CareerStagesEE[] | CareerStagesDE[] = [];

      if (isEEtype) {
        filterValues = programmeDetails
          .flatMap(
            ({ programmeCareerStagesEeList }) => programmeCareerStagesEeList
          )
          .filter((x): x is CareerStagesEE => Boolean(x));
      } else {
        filterValues = programmeDetails
          .flatMap(
            ({ programmeCareerStagesDeList }) => programmeCareerStagesDeList
          )
          .filter((x): x is CareerStagesDE => Boolean(x));
      }
      filterValues.sort();

      if (filterValues.length > 0) {
        const filterSet: Set<string> = new Set([...filterValues]);

        setFilterElement(
          isEEtype ? (
            <div className={clsx("component", "form", styles.form)}>
              {[...filterSet].map((filter, i) => (
                <div className="form-row" key={filter + i}>
                  <EmbeddableCheckbox
                    label={filter || ""}
                    key={slugify(filter) + i}
                    value={slugify(filter)}
                    onChange={handleFilterChange}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="form-row full">
              {[...filterSet].map((filter, i) => (
                <EmbeddableCheckbox
                  label={filter || ""}
                  key={slugify(filter) + i}
                  value={slugify(filter)}
                  onChange={handleFilterChange}
                />
              ))}
            </div>
          )
        );
      }
    }
  }, [programmeDetails]);

  return (
    <div className={clsx("outer-wrap", useBlueCssClassExcpression)}>
      <Grid row>
        <Grid column sm={12}>
          <div
            className={clsx(
              "component",
              "programmes-by-career-stage",
              isEEtype
                ? styles["programmes-by-career-stage"]
                : styles["programmes-by-career-stage-comparison"],
              "wrapper",
              useBlueCssClassExcpression
            )}
          >
            {heading1 && (
              <div className={styles["top-heading"]}>
                <span className={styles["section-title"]}>{heading1}</span>
              </div>
            )}
            <div className={styles["title-with-intro"]}>
              {heading2 && (
                <div className={styles["left-section"]}>
                  <h2>{heading2}</h2>
                </div>
              )}
              <div className={styles["right-section"]}>
                {introText && <p>{introText}</p>}
                {isEEtype && (
                  <>
                    <AccordionContainer
                      noHeading
                      isDropdown
                      customClass={styles.dropdown}
                      accordionFields={{
                        singleDisplay: false,
                        defaultOpen: false,
                        accordionItems: [
                          {
                            fields: {
                              title: (
                                <>
                                  <span>
                                    {
                                      programmeSettings.programmeCareerStageDropDownLabel
                                    }
                                  </span>
                                  <span className="chevron"></span>
                                </>
                              ),
                              content: filterElement,
                            },
                          },
                        ],
                        title:
                          programmeSettings.programmeCareerStageDropDownLabel ||
                          "",
                      }}
                    />
                    {selectedFilters.size > 0 &&
                      noItemsFound &&
                      getErrorMessage()}
                  </>
                )}
              </div>
            </div>

            {!isEEtype && (
              <Grid
                row
                customClass={clsx("outer-wrap", useBlueCssClassExcpression)}
              >
                <div className={clsx(styles.filters, styles.form, "form")}>
                  <label className={styles["options-label"]}>
                    {programmeSettings.programmeCareerStageDropDownLabel}
                  </label>
                  {filterElement}
                </div>
                {selectedFilters.size > 0 && noItemsFound && getErrorMessage()}
              </Grid>
            )}

            <div className={styles["filtered-content"]}>
              {getProgrammeColumns(programmeDetails).map(
                (programmeColumn, i) => (
                  <div key={"column-" + i} className={styles["group-col"]}>
                    {getProgrammeMarkup(programmeColumn)}
                  </div>
                )
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProgrammesByCareerStage;
